import { createApp } from 'vue';
import App from './App.vue';
import './assets/main.css'; // Import global styles, if any

const app = createApp(App);

// If you have global components or plugins, register them here
// Example: app.component('GlobalComponent', GlobalComponent);

// Mount the Vue application to the DOM
app.mount('#app');