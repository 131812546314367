<template>
  <div>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div id="map" style="height: 100vh;" v-else></div>
  </div>
</template>

<script>
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import debounce from '../utils/debounce';

// Fix the default Leaflet icon paths
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: '/images/marker-icon-2x.png',
  iconUrl: '/images/marker-icon.png',
  shadowUrl: '/images/marker-shadow.png',
});

export default {
  name: 'MapView',
  data() {
    return {
      map: null,
      markerClusterGroup: null,
      isLoading: false,
      markersData: [],
    };
  },
  mounted() {
    this.initializeMap();
    this.map.on('moveend', debounce(this.onMapMove, 500));  // Debounce API requests
    this.onMapMove(); // Initial fetch of data
  },
  methods: {
    initializeMap() {
      this.map = L.map('map', {
        maxZoom: 13,
        minZoom: 8,  // Set this to a higher number to limit zoom-out
        maxBounds: [
          [-85, -180], // Southwest corner
          [85, 180]    // Northeast corner
        ],
        maxBoundsViscosity: 1.0, // Keep user within bounds
      }).setView([51.505, -0.09], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(this.map);

      this.markerClusterGroup = L.markerClusterGroup();
      this.map.addLayer(this.markerClusterGroup);
    },
    onMapMove() {
      const bounds = this.map.getBounds();
      const zoomLevel = this.map.getZoom();

      if (zoomLevel >= 10) {
        const latMin = bounds.getSouthWest().lat.toFixed(6);
        const lonMin = bounds.getSouthWest().lng.toFixed(6);
        const latMax = bounds.getNorthEast().lat.toFixed(6);
        const lonMax = bounds.getNorthEast().lng.toFixed(6);

        this.fetchAndDisplayData(latMin, lonMin, latMax, lonMax);
      } else {
        console.warn('Zoom in to see air quality data');
      }
    },
    async fetchAndDisplayData(latMin, lonMin, latMax, lonMax) {
      this.isLoading = true;
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/airrohr/v1/filter/box=${latMin},${lonMin},${latMax},${lonMax}`);
        const sensorData = response.data;

        if (sensorData.length === 0) {
          console.warn('No air quality sensors found in this area.');
          return;
        }

        this.markerClusterGroup.clearLayers();

        sensorData.forEach(sensor => {
          const latitude = parseFloat(sensor.location.latitude).toFixed(6);
          const longitude = parseFloat(sensor.location.longitude).toFixed(6);

          if (!isNaN(latitude) && !isNaN(longitude)) {
            const pm10 = sensor.sensordatavalues.find(val => val.value_type === 'P1');
            const pm25 = sensor.sensordatavalues.find(val => val.value_type === 'P2');

            const markerIcon = L.divIcon({
              className: 'custom-marker',
              html: `<div class="marker-icon" style="background-color:${this.getColor(pm10 ? pm10.value : null)}"></div>`,
            });

            const marker = L.marker([latitude, longitude], { icon: markerIcon })
              .bindPopup(`<b>PM10:</b> ${pm10 ? pm10.value : 'N/A'} µg/m³<br><b>PM2.5:</b> ${pm25 ? pm25.value : 'N/A'} µg/m³`);
            this.markerClusterGroup.addLayer(marker);
          }
        });
      } catch (error) {
        console.error('Error fetching air quality data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    getColor(pmValue) {
      if (pmValue === null) return '#999';
      if (pmValue <= 12) return '#00e400';
      if (pmValue <= 35) return '#ffff00';
      if (pmValue <= 55) return '#ff7e00';
      if (pmValue <= 150) return '#ff0000';
      return '#8b0000';
    }
  }
};
</script>

<style scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #333;
}

.custom-marker .marker-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
  display: inline-block;
}
</style>